<template>
  <div class="container app-content pt-4">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader title="Site Settings" class="mb-4" />

      <div class="row">
        <div class="col-sm-4" v-for="(setting, i) in settings" :key="i">
          <SettingsCard :original="setting" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Error from '../components/Error';
import hasTimeDate from '../mixins/hasTimeDate';
import hasUser from '../mixins/hasUser';
import Loading from '../components/Loading';
import PageHeader from '../components/PageHeader';
import SettingsCard from '../components/settings/SettingsCard';

export default {
  name: 'Settings',
  mixins: [hasTimeDate, hasUser],
  components: {
    SettingsCard,
    Error,
    Loading,
    PageHeader,
  },
  data() {
    return {
      error: null,
      isLoading: true,
      settings: [],
      meta: {},
    };
  },
  computed: {},
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      this.$store
        .dispatch('settings/all')
        .then((response) => {
          this.settings = response.data.data;
          this.meta = response.meta;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
