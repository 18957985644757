<template>
  <div class="card settings-card mb-4 w-100">
    <div class="card-body card-body-extend">
      <h5 class="card-title">
        {{ setting.label }}
      </h5>
      <dl class="row">
        <!-- <template v-for="(meta, i) in metaArray">
          <dt class="col-sm-4" :key="`dt-${i}`">
            {{ meta.label }}
          </dt>
          <dd class="col-sm-8" :key="`dd-${i}`">
            {{ meta.value }}
          </dd>
        </template> -->
      </dl>
    </div>
    <div class="card-footer">
      <button
        v-if="can('update', 'settings')"
        class="btn btn-secondary btn-sm mr-2"
        type="button"
        data-toggle="modal"
        :data-target="`#${modalSlug}`"
        @click="$emit('setSetting', setting)"
      >
        Edit
      </button>
      <small class="text-muted">
        Updated:
        <span :title="fullDateTime(setting.updated_at)">{{ timeAgo(setting.updated_at) }}</span>
      </small>
    </div>

    <Modal :id="modalSlug">
      <template v-slot:title>Edit {{ setting.label }}</template>

      <template v-slot:body>
        <component
          v-if="!isLoading"
          :is="formName"
          :initial="setting"
          :modal="`#${modalSlug}`"
          @complete="fetchData()"
        ></component>
      </template>
    </Modal>
  </div>
</template>

<script>
import * as _ from 'lodash';
import hasTimeDate from 'mixins/hasTimeDate';
import Modal from 'components/Modal';
import PhoneLineCameraForm from 'components/forms/settings/PhoneLineCameraForm';

export default {
  name: 'SettingsCard',
  components: { Modal, PhoneLineCameraForm },
  mixins: [hasTimeDate],
  props: {
    original: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: false,
      isLoading: false,
      setting: {
        label: '',
        meta: {},
      },
    };
  },
  computed: {
    formName() {
      return `${_.upperFirst(_.camelCase(this.setting.label))}Form`;
    },
    metaArray() {
      return _.isObject(this.setting.meta)
        ? Object.keys(this.setting.meta).map((key) => ({
            label: key,
            value: this.setting.meta[key],
          }))
        : [];
    },
    modalSlug() {
      return `modal-site-settings-${_.kebabCase(this.setting.label)}`;
    },
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    fetchData() {
      this.error = false;
      this.isLoading = true;

      const payload = {
        id: this.setting.setting_id,
      };

      this.$store
        .dispatch('settings/get', payload)
        .then((response) => {
          this.setting = response.data.data;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    initSetting() {
      // init label
      const setting = {
        setting_id: this.original.setting_id,
        label: this.original.label,
        meta: {},
      };
      // loop thru meta
      if (_.isObject(this.original.meta)) {
        Object.keys(this.original.meta).forEach((key) => {
          setting.meta[key] = this.original.meta[key];
        });
      }
      // save to data()
      this.setting = setting;
    },
  },
};
</script>

<style></style>
